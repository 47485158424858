import { inject, Injectable } from "@angular/core";

import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";

@Injectable({ providedIn: "root" })
export class NotificationMessageService {
  private translation = inject(TranslateService);
  private snackBar = inject(MatSnackBar);

  showSuccessMessage(message: string) {
    this.snackBar.open(this.translation.instant(message), "×", {
      panelClass: ["success-message"],
      duration: 2000,
    });
  }

  showWarningMessage(message: any) {
    this.snackBar.open(this.translation.instant(message), "×", {
      panelClass: "warning-message",
      duration: 3000,
    });
  }

  showErrorMessage(message: string) {
    this.snackBar.open(this.translation.instant(message), "×", {
      panelClass: ["error-message"],
      duration: 6000,
    });
  }
}
